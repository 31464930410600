@use '@angular/material' as mat;
@use 'sass:map';

$my-theme: mat.define-theme((
    color: (
        primary: mat.$blue-palette,
        tertiary: mat.$orange-palette,
    ),
));

$primary-color: mat.get-theme-color($my-theme, primary, 25);
$primary-light-color: mat.get-theme-color($my-theme, primary, 90);

$accent-color: #ff4081;

$warn-color: #f44336;

$disabled-light-color: #e2e5ed
