@use '@angular/material' as mat;
@import "variables";

@include mat.core();

$my-custom-level: mat.m2-define-typography-level(
    $font-size: 14px,
);

$my-typography: mat.m2-define-typography-config(
    $body-1: $my-custom-level
);

$my-theme: mat.m2-define-light-theme((
    typography: $my-typography,
));

@include mat.all-component-themes($my-theme);

body {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    margin: 0;
    padding: 0;
}

html, body {
    height: 100vh;
    max-width: 100%;
}

.flex-column {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 4rem;
}

.flex-row {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    .actions {
        padding-left: 2rem;
        display: flex;
        flex-direction: row;
        gap: 0.5rem;

        height: 36px;

        & > * {
            flex-grow: 0;
        }
    }
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
mat-card.infoCard {
    padding: 1.5rem 1rem 0.5rem 1rem;
    color: #525252;

    display: flex;
    flex-direction: column;

    mat-card-header {
        justify-content: space-between;
        flex-direction: row-reverse;
        padding: 16px 0 0;

        & > mat-icon {
            background-color: #3674cc;
            border-radius: unset;
            color: white;

            display: flex;
            justify-content: center;
            align-items: center;

            height: 55px;
            width: 55px;

            @include mat.elevation(4);

            position: relative;
            top: -68px;

        }

        .mat-mdc-card-subtitle {
            padding-top: 0.5rem !important;
            text-transform: uppercase;
        }

    }

    .mat-mdc-card-content {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 1rem;
    }

    mat-expansion-panel {
        box-shadow: none !important;

        .mat-expansion-panel-body {
            padding: 0;
        }
    }

    /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
    mat-card-actions {
        flex-direction: row-reverse;

        button, a {
            text-transform: uppercase;
        }
    }
}

mat-dialog-actions {

    button, a {
        text-transform: uppercase !important;
    }
}

/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
.mat-list-item-content {
    & > mat-icon {
        margin-right: 1rem;
        width: 35px !important;
        height: 43px !important;
        font-size: 35px !important;
    }
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    border-top-color: #000000;
    animation: spinner .8s linear infinite;
}

article.page {
    padding: 2rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;

    & > header {
        flex-grow: 1;
        color: #4d4d4d;
        display: flex;
        gap: 2rem;
        flex-direction: row;
        justify-content: flex-end;
        align-items: baseline;

        h1 {
            text-transform: capitalize;
            flex-grow: 1;
        }

        .pageActions button {
             margin-left: 1rem;
        }
    }

    .cards-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 3rem;
    }

    div.content {
        display: flex;
        width: 100%;
        gap: 2rem;
        row-gap: 4rem;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;

        form {
            min-width: 30rem;
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }

        .archive {
            flex-grow: 1;
            /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
            mat-card-header {
                mat-icon {
                    background-color: #b7b6b6;
                }
            }
        }

        .administrative-documents {
            /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
            mat-card-header {
                mat-icon {
                    background-color: #cda05c;
                }
            }
        }


        .details {
            /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
            mat-card-header {
                mat-icon {
                    background-color: #d35d92;
                }
            }
        }

        .accountant {
            flex-grow: 1;
            /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
            mat-card-header {
                mat-icon {
                    background-color: cadetblue;
                }
            }
        }


        leave-profile {
            flex-grow: 1;
        }

        medical-profile {
            min-width: 10rem;
            flex-grow: 1;
        }

        /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
        /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
        mat-card.employees {
            /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
            mat-card-header {
                mat-icon {
                    background-color: #ea944c;
                }
            }

            section {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
            }
        }

    }

    main {
        flex-grow: 1;
    }
}


mat-error.mat-mdc-form-field-error {
    font-size: 0.7rem;
}


table {

    tr.bad {
        background: rgba(255, 202, 202, 0.28);
    }

    tr.mat-mdc-row, tr.mat-mdc-footer-row, tr.mat-mdc-no-data-row {
        height: 54px;
    }

    tr.nested {
        background: rgba(243, 243, 243, 0.76);
        font-weight: 300;
    }

    tr.highlighted {
        font-weight: 500;
        background: rgba(191, 216, 255, 0.28);

        td {
            border-bottom: black;
        }
    }

    tr:not(.mat-mdc-header-row):hover {
        background: lightyellow;
    }

    td.bad {
        color: #f44336;
    }
}

.entityDetails {
    margin-top: 1rem;
    margin-bottom: 2rem;

    .label {
        font-weight: 500;
        width: 40%;
    }
}

.mat-mdc-dialog-actions {
    justify-content: end;
}

.mat-mdc-radio-button ~ .mat-radio-button {
    margin-left: 16px;
    margin-top: 16px;
    margin-bottom: 16px;
}

.mdc-text-field {
    padding: 0 5px !important;
}

.mat-mdc-form-field-focus-overlay {
    background-color: transparent !important;
}

.mdc-text-field--filled {
    background-color: transparent !important;
}


/* TODO(mdc-migration): The following rule targets internal classes of paginator that may no longer apply for the MDC version. */
mat-form-field:not(.mat-mdc-paginator-page-size-select), document-input {
    width: 100%;

    &.flexible-size {
        width: unset;
        flex-grow: 1;
    }

    &.fixed-size {
        width: 5rem;
        flex-grow: 1;
    }

    &.fixed-size.date {
        width: 12rem;
        flex-grow: 1;
        flex-shrink: 0;
    }

}

document-input.fixed-size {
    width: 300px;
    flex-shrink: 0;
}

.mat-form-field-disabled *, .mat-mdc-select-disabled {
    color: #868686 !important;
    cursor: not-allowed !important;
}

//.mat-mdc-form-field * {
//     font-size: 14px !important;
//}

td.currencyColumn, th.currencyColumn {
    text-align: right;

    .mat-sort-header-container {
        justify-content: flex-end;
    }
}

td.currencyColumn {
    padding-right: 32px;
}


.notificationSnack {
    transform: translateY(70px) !important;
    text-align: center;

}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    padding-top: 31px;
}
.mat-mdc-form-field-icon-suffix {
    padding-top: 15px !important;
}


.tag {
    padding: 0.5rem 1rem;
    color: white;
    font-size: small;
    border-radius: 15px;
    background: $primary-color;

    &.warning {
        background: $warn-color;
    }
}

.mat-mdc-card-header .headerWithCommand {
    margin-left: 1.5rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.summaryCard {
    min-width: 20rem;

    table {
        margin-top: 1rem;
        padding: 0;
    }

    tr {
        height: 35px;

        &.alert {
            color: crimson;
        }
    }

}

.addressFormPart {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    width: 100%;

    .nation {
        width: 8rem;
    }

}
